<template>
  <v-stepper-header>
    <v-stepper-step v-if="wizardState.general.visible" :editable="step > wizardState.general.step" :complete="step > wizardState.general.step" :step="wizardState.general.step">
      Event Basics
    </v-stepper-step>

    <v-divider  v-if="wizardState.general.visible" />

    <v-stepper-step v-if="wizardState.parameters.visible" :editable="step > wizardState.parameters.step" :complete="step > wizardState.parameters.step" :step="wizardState.parameters.step">
      Options 
    </v-stepper-step>

    <v-divider  v-if="wizardState.parameters.visible" />

    <v-stepper-step v-if="wizardState.decorators.visible" :editable="step > wizardState.decorators.step" :complete="step > wizardState.decorators.step" :step="wizardState.decorators.step">
      Configuration
    </v-stepper-step>

    <v-divider  v-if="wizardState.decorators.visible" />

    <v-stepper-step v-if="wizardState.leaderboards.visible" :editable="step > wizardState.leaderboards.step" :complete="step > wizardState.leaderboards.step" :step="wizardState.leaderboards.step">
      Leaderboards
    </v-stepper-step>
  </v-stepper-header>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import EventUtil from "@/util/eventUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "EventTemplateWizardHeader",
  components: {
  },
  props: {
    template: Object,
    step: Number,
  },
  data() {
    return {
      tenant: tenant,
    };
  },
  async mounted() {
    
  },
  methods: {

    configurableDecorators(decorators) {
      return decorators
          && decorators.filter(x => !x.if_parameter || this.isParameterEnabled(x.if_parameter));
    },

    isParameterEnabled(key) {
      return this.template && this.template.parameters && this.template.parameters.some(x => x.key == key && x.enabled);
    },    
  },
  
  computed: {

    wizardState() {
      let generalState = { visible: true, step: 1 };
      let parameterState = { visible: this.template.parameters.length > 0, step: 2 };
      let decoratorState = { visible: this.configurableDecorators(this.template.decorators).length > 0, step: parameterState.visible ? 3 : 2 };
      let leaderboardState = { visible: true, step: [generalState, parameterState, decoratorState].filter(x => x.visible).length + 1 };
      return {
        general: generalState,
        parameters: parameterState,
        decorators: decoratorState,
        leaderboards: leaderboardState,
      }
    },

    
    ...mapGetters({
      user: "user"
    })
  },

};
</script>

<style lang="scss">

</style>

