<template>
    <v-card 
      :class="{ 'd-flex flex-column grow': true, 'card--featured': item.featured}" 
      @click="hasClickListener ? $emit('click') : null" 
      :to="to ? to : (routeName ? {name: routeName, params: {id : item.id }} : null)"
      >
      <div v-if="item.featured && !mini" class="featured-badge" title="Featured">
        <v-icon color="black">fa-star</v-icon>
      </div>
      <v-img
        v-if="item.tile_img"
        :src="item.tile_img"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        :height="mini ? '100px' : '200px'"
      > 
        <v-card-title>{{item.name | trim(50)}}<v-icon v-if="!hideIndicator" color="white" small class="ms-2">fa-chevron-right</v-icon></v-card-title>
        <v-card-text v-if="!mini" class=" challenge-summary">{{item.summary}}</v-card-text>
      </v-img>
      <v-sheet v-else class="d-flex flex-column justify-end " :color="$helpers.getGradientColor(0)" :height="mini ? 100 : 200">
        <v-card-title>{{item.name}} <v-icon v-if="!hideIndicator" color="black" small class="ml-2">fa-chevron-right</v-icon></v-card-title>
        <v-card-text v-if="!mini" class=" challenge-summary">{{item.summary}}</v-card-text>
      </v-sheet>
      <v-card-text v-if="item.tags && !mini" class="grey lighten-3 grow pa-3 challenge-tags">
          <v-chip v-for="(item,idx) in item.tags" :key="idx" x-small color="accent" outlined class="me-2">{{ item.replace('_', '-') }}</v-chip>
      </v-card-text>
    </v-card>    
</template>
 
<script>

export default {
  name: "EventTemplateCard",
  components: {
  },
  props: {
    item: Object,
    to: [Object, String],
    routeName: String,
    mini: Boolean,
    hideIndicator: Boolean,
  },
  data: function() {
    return {
    };
  },
  mounted() {
  },
  methods: {
      
  },
  computed: {
    hasClickListener(){
      return this.$listeners && this.$listeners.click;
    }
  }
};
</script>

<style lang="scss">
/*.featured-badge { display: none; }
.challenge-tags { display: none; }
.challenge-summary { display: none; }*/
</style>

        